import translation_de from "./de-DE/translation.json";
import translation_en from "./en-US/translation.json";
import translation_es from "./es-ES/translation.json";
import translation_fr from "./fr-FR/translation.json";
import translation_it from "./it-IT/translation.json";

export const locales = {
    de: {
        translation: translation_de
    },
    en: {
        translation: translation_en
    },
    es: {
        translation: translation_es
    },
    fr: {
        translation: translation_fr
    },
    it: {
        translation: translation_it
    }
}